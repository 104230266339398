import { appVersion } from './app-version';
import { AppConfiguration } from './AppConfiguration';

export const environment: AppConfiguration = {
    production: true,
    instana: true,
    serviceWorkerEnabled: false,
    version: appVersion,
    env: 'production',
    app: {
        defaultLanguage: 'de-DE',
        supportedLanguages: [
            { locale: 'de-DE', flag: 'de', salesOrg: '0500' },
            { locale: 'en-DE', flag: 'de', salesOrg: '0500' },
            { locale: 'de-AT', flag: 'at', salesOrg: '5000' },
            { locale: 'fr-BE', flag: 'be', salesOrg: '6000' },
            { locale: 'nl-BE', flag: 'be', salesOrg: '6000' },
            { locale: 'fr-FR', flag: 'fr', salesOrg: '0600' },
            { locale: 'de-CH', flag: 'ch', salesOrg: '6200' },
            { locale: 'fr-CH', flag: 'ch', salesOrg: '6200' },
            { locale: 'it-CH', flag: 'ch', salesOrg: '6200' },
            { locale: 'it-IT', flag: 'it', salesOrg: '7300' },
            { locale: 'sk-SK', flag: 'sk', salesOrg: '7900' },
            { locale: 'pl-PL', flag: 'pl', salesOrg: '9500' },
            { locale: 'cs-CZ', flag: 'cz', salesOrg: '9700' },
            { locale: 'de-LU', flag: 'lu', salesOrg: '6100' },
            { locale: 'fr-LU', flag: 'lu', salesOrg: '6100' },
            { locale: 'es-ES', flag: 'es', salesOrg: '7100' },
            { locale: 'sl-SI', flag: 'si', salesOrg: '7500' },
            { locale: 'tr-TR', flag: 'tr', salesOrg: '8000' },
        ],
        priceAdvantageNotRelevantSalesOrgs: ['0600', '6200'],
        productCategoryGroupNameMapSalesOrg: ['6200'],
    },
    appInsights: {
        enabled: true,
        connectionString:
            'InstrumentationKey=49372b9a-f246-4022-bf13-09d7f1af652f;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/',
    },
    quest: {
        context: 'quest',
        dropoutGroup: 'Dropout',
        project: 'QuoteAssist',
        modelSelectionVariable: 'Anonymous.ProductGroup',
        preselectionVariable: 'Miscellaneous.PreSelection',
    },
    configit: {
        url: 'https://api.viessmann.com/backend/package-packer/v2',
        languages: ['de_DE'],
        salesAreaName: 'Germany',
        plant: '0500',
        usage: '5',
    },
    offline: {
        enabled: false,
        keyword: 'offline=1',
        baseUrl: 'https://quote-assist.viessmann.com/assets/offline/',
    },
    http: {
        shopUrl: 'https://shop.viessmann.com/?shopApiContext=qa-import&offerNumber=',
        foerderProfiUrl:
            'https://foerder-profi.de/?utm_source=angebots_assistent&utm_medium=referral&utm_campaign=kostenfreie_pruefung',
        baseUrl: 'https://api.viessmann.com/backend/package-packer/v2/',
        baseClientUrl: 'https://quote-assist.viessmann.com/',
        clientUrl: 'https://quote-assist.viessmann.com/overview',
        viBooksBaseUrl: 'https://webapps.viessmann.com/vibooks',
        users: 'https://api.viessmann.com/users/v1/users/me?sections=identity&sections=sales&sections=roles',
        translations:
            'https://api.viessmann.com/translations/v2/phraseapp-proxy/projects/f904ba58454342e5ede6967b374ef7f9/locales/',
        dswTranslations:
            'https://api.viessmann.com/translations/v2/phraseapp-proxy/projects/ec38b083a23c58c604a49d7f443dd7f2/locales/',
        statusPage: 'https://api.viessmann.com/status/v1/apps/rhwny5sryyyt/summary',
        configuration: 'https://api.viessmann.com/backend/configuration/v1/',
        quotation: 'https://api.viessmann.com/quotation/v1/',
        viGuidePlanningUrl: 'https://viguide-planning.viessmann.com/project/',
        faq: {
            '0500': 'https://partnerportal.viessmann.com/de/de/support/faq/quote-assist.html',
            default: 'https://support.viessmann.com/?page=1&pageSize=8',
        },
        companyUrl: 'https://api.viessmann.com/company/v3/companies/',
        pim: 'https://api.viessmann.com/backend/pim/v1/',
    },
    globalHeader: {
        baseUrl: 'https://api.viessmann.com',
        accountAppUrl: 'https://account.viessmann.com',
    },
    saml: {
        appId: 'quote-assist',
        baseUrl: 'https://api.viessmann.com',
    },
    file: {
        uploadUrl: 'upload',
        downloadUrl: 'download',
    },
    feature: {
        salesorgSwitcher: false,
    },
    requireSalesOrg: true,
    vkOrgsAuthorziedToUseTemplates: ['0500'],
};
