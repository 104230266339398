import { Injectable } from '@angular/core';
import { AppService } from '../../services/app.service';
import { PermissionService } from '../../services/permission.service';
import { ProductsCategoryService } from '../../services/products-category.service';
import { Product, ProductGroup } from '../../services/products.service';
import { QuoteDiscount, QuoteDiscountsService } from '../../services/quote-discounts.service';
import { BehaviorSubject } from 'rxjs';
import { PriceParams } from './price-summary.model';
import { TranslateService } from '@ngx-translate/core';

const categoryMapping = {
    AGN: 'ZAGN',
    AGNN: 'ZAGX',
    AGX: 'ZAGX',
};

@Injectable({
    providedIn: 'root',
})
export class PriceSummaryHelperService {
    private materialCategory: Map<string, string> = new Map<string, string>();

    public priceAdvantageEnabled$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    constructor(
        private permissionService: PermissionService,
        private quoteDiscountsService: QuoteDiscountsService,
        private appService: AppService,
        private productCategory: ProductsCategoryService,
        private translate: TranslateService
    ) {}

    public setCategoryForMaterial(material: string, category: string) {
        const mappedCategory = categoryMapping[category];
        if (mappedCategory) {
            this.materialCategory.set(material, mappedCategory);
        }
    }

    public getParamForQuoteClose(groupedProducts: ProductGroup[], customerId: string) {
        const params = this.getPriceParams(customerId);

        params.groupedMaterials = groupedProducts.map((group) => ({
            name: this.translate.instant(group.translationKey),
            materials: group.products.map(this.convertProduct),
        }));

        return params;
    }

    public getParamForPriceSummary(products: Product[], customerId: string) {
        const params = this.getPriceParams(customerId);

        params.materials = products.map(this.convertProduct);

        return params;
    }

    private convertProduct(product: Product) {
        return {
            materialNumber: product.material,
            amount: product.quantity,
            category: product.optional ? this.materialCategory.get(product.material) : undefined,
        };
    }

    private getPriceParams(customerId: string): PriceParams {
        const salesOrg = this.appService.getSalesOrg();
        const priceAdvantageEnabled = this.priceAdvantageEnabled$.value;
        const param: PriceParams = {
            salesOrg,
            discounts: [],
            priceAdvantageEnabled,
        };
        if (customerId && customerId !== '') {
            param.customerId = customerId;
        }

        if (priceAdvantageEnabled) {
            param.discounts = this.productCategory.getProductDiscountCategoriesFromCache();
        }

        if (this.permissionService.isUserAnEmployee && this.quoteDiscountsService.getQuoteDiscounts().length > 0) {
            param.partnerDiscounts = this.quoteDiscountsService.getQuoteDiscounts();
        }

        return param;
    }

    public hasProductListChanged(array1: Array<any>, array2: Array<any>) {
        const arrayString1 = JSON.stringify(array1);
        const arrayString2 = JSON.stringify(array2);
        const result = arrayString1 !== arrayString2;
        return result;
    }

    public quoteDiscountsDiffer(prev: Array<QuoteDiscount>, current: Array<QuoteDiscount>): boolean {
        if (prev == null) {
            return true;
        }
        // 1. We convert each discount to a normalized string representation: GG_15
        // 2. we check the difference in both ways to determine if the arrays are equal
        const prevStrings = prev.map((it) => it.materialGroup + '_' + String(it.partnerDiscount));
        const currentStrings = current.map((it) => it.materialGroup + '_' + String(it.partnerDiscount));
        const diff1 = prevStrings.filter((it) => !currentStrings.includes(it));
        const diff2 = currentStrings.filter((it) => !prevStrings.includes(it));
        return diff1.length + diff2.length > 0;
    }
}
