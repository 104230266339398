<app-main-content content>
    <div main fxLayout="row" fxLayoutGap="10px">
        <a mat-button color="primary" [href]="callbackUrl" *ngIf="callbackUrl">
            <mat-icon>arrow_back</mat-icon>
            {{ 'NEXT_STEPS.EXTERNAL_APP.CALLBACK_URL' | translate }}
        </a>
        <ng-container *ngIf="!callbackUrl">
            <a mat-icon-button [routerLink]="planning ? ['../', 'planning-project', salesforceId] : ['/overview']">
                <mat-icon>arrow_back</mat-icon>
            </a>
            <app-breadcrumbs
                formerPage="{{
                    (planning ? 'COMMON.BREADCRUMBS.PLANNING_PROJECT' : 'COMMON.BREADCRUMBS.OVERVIEW') | translate
                }}"
                currentPage="{{ 'COMMON.BREADCRUMBS.NEXT_STEPS' | translate }}"
                [navigateTo]="planning ? ['../', 'planning-project', salesforceId] : '/overview'"
            ></app-breadcrumbs>
        </ng-container>
    </div>

    <h2 main class="title">{{ 'NEXT_STEPS.TITLE' | translate }}</h2>

    <div main fxLayout="column" fxLayoutGap="10px">
        <app-documents-selection
            [sapDocumentId]="sapDocumentId"
            [documentId]="documentId"
            [latestRevision]="latestRevision"
            [printType]="printType"
        ></app-documents-selection>
    </div>
    <div main fxLayout="row" fxLayoutAlign="end top" fxLayoutGap="10px">
        <a mat-button color="primary" [href]="callbackUrl" *ngIf="callbackUrl">
            <mat-icon>arrow_back</mat-icon>
            {{ 'NEXT_STEPS.EXTERNAL_APP.CALLBACK_URL' | translate }}
        </a>
        <a mat-button color="primary" [routerLink]="['../', 'planning-project', salesforceId]" *ngIf="planning">
            <mat-icon>arrow_back</mat-icon>
            {{ 'NEXT_STEPS.PLANNING.BACK_TO_PROJECT_OVERVIEW' | translate }}
        </a>
        <button mat-button color="primary" [routerLink]="['/overview']" *ngIf="!planning && !callbackUrl">
            <mat-icon>arrow_back</mat-icon>
            {{ 'NEXT_STEPS.BUTTON.BACK_TO_OVERVIEW' | translate }}
        </button>
        <a
            *ngIf="appService.getLocation() === 'DE'"
            mat-raised-button
            color="primary"
            target="_blank"
            [href]="foerderProfiUrl"
        >
            <mat-icon svgIcon="coin-stack"></mat-icon> {{ 'NEXT_STEPS.BUTTON.OPEN_FOERDER_PROFI' | translate }}
        </a>
        <a mat-raised-button color="primary" target="_blank" [href]="shopUrl">
            <mat-icon>shopping_cart</mat-icon>
            {{ 'NEXT_STEPS.BUTTON.ORDER_ONLINE_IN_PARTNER_SHOP' | translate }}
        </a>
    </div>
</app-main-content>
